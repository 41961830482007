/* tslint:disable */
/* eslint-disable */
/**
 * E.ON Home Admin Portal - API
 * # Introduction    This API provides access to admin and customer management.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: sebastian.eggers@eon.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ActivityLogFilter,
  AdminUserRequestPreferencesModel,
  AdminUserResponsePreferencesModel,
  BadRequest,
  Forbidden,
  MultiFactorAddModel,
  ProfileMultifactorResponseModel,
  ProfileRequestModel,
  ProfileResponseModel,
  Unauthorized,
} from '../models';

export interface ProfileAddUserPreferencesRequest {
    adminUserRequestPreferencesModel: AdminUserRequestPreferencesModel;
}

export interface ProfileEditProfileRequest {
    profileRequestModel: ProfileRequestModel;
}

export interface ProfileGetAuditLogsForMyActivityRequest {
    filter?: ActivityLogFilter;
    page?: number;
    limit?: number;
}

export interface ProfileUpdateUserPreferencesRequest {
    adminUserRequestPreferencesModel: AdminUserRequestPreferencesModel;
}

export interface ProfileValidateMultiFactorAuthenticationRequest {
    multiFactorAddModel: MultiFactorAddModel;
}

/**
 * 
 */
export class ProfileApi extends runtime.BaseAPI {

    /**
     * Create user preferences
     */
    async profileAddUserPreferencesRaw(requestParameters: ProfileAddUserPreferencesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AdminUserResponsePreferencesModel>> {
        if (requestParameters.adminUserRequestPreferencesModel === null || requestParameters.adminUserRequestPreferencesModel === undefined) {
            throw new runtime.RequiredError('adminUserRequestPreferencesModel','Required parameter requestParameters.adminUserRequestPreferencesModel was null or undefined when calling profileAddUserPreferences.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/profile/me/preferences`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.adminUserRequestPreferencesModel,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Create user preferences
     */
    async profileAddUserPreferences(requestParameters: ProfileAddUserPreferencesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AdminUserResponsePreferencesModel> {
        const response = await this.profileAddUserPreferencesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete Account multifactor
     */
    async profileDeleteMultiFactorAuthenticationRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/profile/multifactor`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete Account multifactor
     */
    async profileDeleteMultiFactorAuthentication(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.profileDeleteMultiFactorAuthenticationRaw(initOverrides);
    }

    /**
     * Edit Account Information
     */
    async profileEditProfileRaw(requestParameters: ProfileEditProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProfileResponseModel>> {
        if (requestParameters.profileRequestModel === null || requestParameters.profileRequestModel === undefined) {
            throw new runtime.RequiredError('profileRequestModel','Required parameter requestParameters.profileRequestModel was null or undefined when calling profileEditProfile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/profile/me`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.profileRequestModel,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Edit Account Information
     */
    async profileEditProfile(requestParameters: ProfileEditProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProfileResponseModel> {
        const response = await this.profileEditProfileRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get list of audit logs
     */
    async profileGetAuditLogsForMyActivityRaw(requestParameters: ProfileGetAuditLogsForMyActivityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.filter !== undefined) {
            queryParameters['filter'] = requestParameters.filter;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/profile/me/activities`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Get list of audit logs
     */
    async profileGetAuditLogsForMyActivity(requestParameters: ProfileGetAuditLogsForMyActivityRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.profileGetAuditLogsForMyActivityRaw(requestParameters, initOverrides);
    }

    /**
     * Get Account Information
     */
    async profileGetProfileRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProfileResponseModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/profile/me`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get Account Information
     */
    async profileGetProfile(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProfileResponseModel> {
        const response = await this.profileGetProfileRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get account preferences
     */
    async profileGetUserPreferencesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AdminUserResponsePreferencesModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/profile/me/preferences`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get account preferences
     */
    async profileGetUserPreferences(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AdminUserResponsePreferencesModel> {
        const response = await this.profileGetUserPreferencesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async profileStartMultiFactorAuthenticationRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProfileMultifactorResponseModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/profile/multifactor`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async profileStartMultiFactorAuthentication(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProfileMultifactorResponseModel> {
        const response = await this.profileStartMultiFactorAuthenticationRaw(initOverrides);
        return await response.value();
    }

    /**
     * Edit user preferences
     */
    async profileUpdateUserPreferencesRaw(requestParameters: ProfileUpdateUserPreferencesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AdminUserResponsePreferencesModel>> {
        if (requestParameters.adminUserRequestPreferencesModel === null || requestParameters.adminUserRequestPreferencesModel === undefined) {
            throw new runtime.RequiredError('adminUserRequestPreferencesModel','Required parameter requestParameters.adminUserRequestPreferencesModel was null or undefined when calling profileUpdateUserPreferences.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/profile/me/preferences`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.adminUserRequestPreferencesModel,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Edit user preferences
     */
    async profileUpdateUserPreferences(requestParameters: ProfileUpdateUserPreferencesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AdminUserResponsePreferencesModel> {
        const response = await this.profileUpdateUserPreferencesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async profileValidateMultiFactorAuthenticationRaw(requestParameters: ProfileValidateMultiFactorAuthenticationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.multiFactorAddModel === null || requestParameters.multiFactorAddModel === undefined) {
            throw new runtime.RequiredError('multiFactorAddModel','Required parameter requestParameters.multiFactorAddModel was null or undefined when calling profileValidateMultiFactorAuthentication.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/profile/multifactor`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.multiFactorAddModel,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async profileValidateMultiFactorAuthentication(requestParameters: ProfileValidateMultiFactorAuthenticationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.profileValidateMultiFactorAuthenticationRaw(requestParameters, initOverrides);
    }

}
