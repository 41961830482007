/* tslint:disable */
/* eslint-disable */
/**
 * E.ON Home Admin Portal - API
 * # Introduction    This API provides access to admin and customer management.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: sebastian.eggers@eon.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AuthenticationRequestModel,
  AuthenticationResponseModel,
  BadRequest,
  Conflict,
  Forbidden,
  IdpSignUpRequestModel,
  Unauthorized,
  UriResponseModel,
} from '../models';

export interface AuthenticationIdpSignUpRequest {
    idpSignUpRequestModel: IdpSignUpRequestModel;
}

export interface AuthenticationLinkRequest {
    lang?: string;
}

export interface AuthenticationLoginRequest {
    authorization: string;
}

export interface AuthenticationSignInMultiFactorRequest {
    authenticationRequestModel: AuthenticationRequestModel;
}

/**
 * 
 */
export class AuthenticationApi extends runtime.BaseAPI {

    /**
     * Create IDP user account
     */
    async authenticationIdpSignUpRaw(requestParameters: AuthenticationIdpSignUpRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.idpSignUpRequestModel === null || requestParameters.idpSignUpRequestModel === undefined) {
            throw new runtime.RequiredError('idpSignUpRequestModel','Required parameter requestParameters.idpSignUpRequestModel was null or undefined when calling authenticationIdpSignUp.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/auth/idp/sign-up`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.idpSignUpRequestModel,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create IDP user account
     */
    async authenticationIdpSignUp(requestParameters: AuthenticationIdpSignUpRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.authenticationIdpSignUpRaw(requestParameters, initOverrides);
    }

    /**
     * Get IDP authorization url
     */
    async authenticationLinkRaw(requestParameters: AuthenticationLinkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UriResponseModel>> {
        const queryParameters: any = {};

        if (requestParameters.lang !== undefined) {
            queryParameters['lang'] = requestParameters.lang;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/auth/authorization-uri`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get IDP authorization url
     */
    async authenticationLink(requestParameters: AuthenticationLinkRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UriResponseModel> {
        const response = await this.authenticationLinkRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Sign-In
     */
    async authenticationLoginRaw(requestParameters: AuthenticationLoginRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AuthenticationResponseModel>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling authenticationLogin.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/api/v1/auth/sign-in`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Sign-In
     */
    async authenticationLogin(requestParameters: AuthenticationLoginRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AuthenticationResponseModel> {
        const response = await this.authenticationLoginRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Sign-Out
     */
    async authenticationLogoutRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/auth/sign-out`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Sign-Out
     */
    async authenticationLogout(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.authenticationLogoutRaw(initOverrides);
    }

    /**
     * Sign-In multi factor authentication
     */
    async authenticationSignInMultiFactorRaw(requestParameters: AuthenticationSignInMultiFactorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AuthenticationResponseModel>> {
        if (requestParameters.authenticationRequestModel === null || requestParameters.authenticationRequestModel === undefined) {
            throw new runtime.RequiredError('authenticationRequestModel','Required parameter requestParameters.authenticationRequestModel was null or undefined when calling authenticationSignInMultiFactor.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/auth/sign-in/multifactor`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.authenticationRequestModel,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Sign-In multi factor authentication
     */
    async authenticationSignInMultiFactor(requestParameters: AuthenticationSignInMultiFactorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AuthenticationResponseModel> {
        const response = await this.authenticationSignInMultiFactorRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get sign-up url
     */
    async authenticationSignUpUriRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UriResponseModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/auth/sign-up-uri`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get sign-up url
     */
    async authenticationSignUpUri(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UriResponseModel> {
        const response = await this.authenticationSignUpUriRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get new token
     */
    async authenticationTokenRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AuthenticationResponseModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/auth/refresh`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get new token
     */
    async authenticationToken(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AuthenticationResponseModel> {
        const response = await this.authenticationTokenRaw(initOverrides);
        return await response.value();
    }

}
