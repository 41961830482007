import { UnknownFunction } from '@eon-home/react-library';

import { FetchError, ResponseError } from '@swagger-http';

export type UnknownFunctionDict = Record<string, UnknownFunction>;

export const handleError = async <T extends ResponseError | FetchError>(
    e: T,
): Promise<T | string> => {
    let error: T | string = e?.message || 'Unable to parse error message.';
    let is401 = false;
    let is404 = false;

    try {
        let clone!: Response;

        if (e instanceof ResponseError) {
            clone = e.response.clone();
        }

        if (e instanceof FetchError) {
            clone = new Response(e.cause.message);
        }

        error = await clone.text();
        is401 = clone.status === 401;
        is404 = clone.status === 404;
    } catch (ex) {}

    if (is401 || is404) {
        return error;
    }

    return error;
};

export const extractErrorMessage = (error: any) => {
    if (!error) {
        return '';
    }
    let errorMessage = error;

    try {
        const errorObject = JSON.parse(error);
        errorMessage = errorObject.msg;
    } catch (e) {}

    return errorMessage;
};
