/* tslint:disable */
/* eslint-disable */
/**
 * E.ON Home Admin Portal - API
 * # Introduction    This API provides access to admin and customer management.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: sebastian.eggers@eon.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BusinessReportDataResponseModel,
  Forbidden,
  ManagerReportOfDevicesConfigurationsResponseModel,
  ManagerReportOfUserActivityResponseModel,
  Unauthorized,
} from '../models';

export interface CrmCustomersBusinessReportsGetCreationReportsRequest {
    aggregation: string;
    fromDate: string;
    toDate: string;
}

export interface CrmCustomersBusinessReportsGetCreationReportsIncrementalRequest {
    aggregation: string;
    fromDate: string;
    toDate: string;
}

export interface CrmCustomersBusinessReportsGetProductReportsRequest {
    aggregation: string;
    fromDate: string;
    toDate: string;
}

export interface CrmCustomersBusinessReportsGetProductReportsByAggregationFromDateToDateStatesRequest {
    aggregation: string;
    fromDate: string;
    toDate: string;
    states: string;
}

export interface CrmCustomersBusinessReportsGetStatusReportsRequest {
    aggregation: string;
    fromDate: string;
    toDate: string;
}

export interface CrmCustomersBusinessReportsGetTenantReportsRequest {
    aggregation: string;
    fromDate: string;
    toDate: string;
}

export interface CrmCustomersBusinessReportsGetVendorReportsRequest {
    aggregation: string;
    fromDate: string;
    toDate: string;
}

export interface CrmCustomersBusinessReportsGetVendorReportsByAggregationFromDateToDateStatesRequest {
    aggregation: string;
    fromDate: string;
    toDate: string;
    states: string;
}

/**
 * 
 */
export class CRMCustomersBusinessReportsApi extends runtime.BaseAPI {

    /**
     * Get status regarding customers creation date
     */
    async crmCustomersBusinessReportsGetCreationReportsRaw(requestParameters: CrmCustomersBusinessReportsGetCreationReportsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BusinessReportDataResponseModel>> {
        if (requestParameters.aggregation === null || requestParameters.aggregation === undefined) {
            throw new runtime.RequiredError('aggregation','Required parameter requestParameters.aggregation was null or undefined when calling crmCustomersBusinessReportsGetCreationReports.');
        }

        if (requestParameters.fromDate === null || requestParameters.fromDate === undefined) {
            throw new runtime.RequiredError('fromDate','Required parameter requestParameters.fromDate was null or undefined when calling crmCustomersBusinessReportsGetCreationReports.');
        }

        if (requestParameters.toDate === null || requestParameters.toDate === undefined) {
            throw new runtime.RequiredError('toDate','Required parameter requestParameters.toDate was null or undefined when calling crmCustomersBusinessReportsGetCreationReports.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/reports/creation/{aggregation}/{fromDate}/{toDate}`.replace(`{${"aggregation"}}`, encodeURIComponent(String(requestParameters.aggregation))).replace(`{${"fromDate"}}`, encodeURIComponent(String(requestParameters.fromDate))).replace(`{${"toDate"}}`, encodeURIComponent(String(requestParameters.toDate))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get status regarding customers creation date
     */
    async crmCustomersBusinessReportsGetCreationReports(requestParameters: CrmCustomersBusinessReportsGetCreationReportsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BusinessReportDataResponseModel> {
        const response = await this.crmCustomersBusinessReportsGetCreationReportsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get status regarding customers creation date incremental
     */
    async crmCustomersBusinessReportsGetCreationReportsIncrementalRaw(requestParameters: CrmCustomersBusinessReportsGetCreationReportsIncrementalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BusinessReportDataResponseModel>> {
        if (requestParameters.aggregation === null || requestParameters.aggregation === undefined) {
            throw new runtime.RequiredError('aggregation','Required parameter requestParameters.aggregation was null or undefined when calling crmCustomersBusinessReportsGetCreationReportsIncremental.');
        }

        if (requestParameters.fromDate === null || requestParameters.fromDate === undefined) {
            throw new runtime.RequiredError('fromDate','Required parameter requestParameters.fromDate was null or undefined when calling crmCustomersBusinessReportsGetCreationReportsIncremental.');
        }

        if (requestParameters.toDate === null || requestParameters.toDate === undefined) {
            throw new runtime.RequiredError('toDate','Required parameter requestParameters.toDate was null or undefined when calling crmCustomersBusinessReportsGetCreationReportsIncremental.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/reports/creation/{aggregation}/{fromDate}/{toDate}/incremental`.replace(`{${"aggregation"}}`, encodeURIComponent(String(requestParameters.aggregation))).replace(`{${"fromDate"}}`, encodeURIComponent(String(requestParameters.fromDate))).replace(`{${"toDate"}}`, encodeURIComponent(String(requestParameters.toDate))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get status regarding customers creation date incremental
     */
    async crmCustomersBusinessReportsGetCreationReportsIncremental(requestParameters: CrmCustomersBusinessReportsGetCreationReportsIncrementalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BusinessReportDataResponseModel> {
        const response = await this.crmCustomersBusinessReportsGetCreationReportsIncrementalRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get list of available report endpoints
     */
    async crmCustomersBusinessReportsGetEndpointsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/reports`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Get list of available report endpoints
     */
    async crmCustomersBusinessReportsGetEndpoints(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.crmCustomersBusinessReportsGetEndpointsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get report of device configurations
     */
    async crmCustomersBusinessReportsGetManagerReportDevicesConfigurationsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ManagerReportOfDevicesConfigurationsResponseModel>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/reports/devicesConfigurations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get report of device configurations
     */
    async crmCustomersBusinessReportsGetManagerReportDevicesConfigurations(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ManagerReportOfDevicesConfigurationsResponseModel>> {
        const response = await this.crmCustomersBusinessReportsGetManagerReportDevicesConfigurationsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get report of user activity
     */
    async crmCustomersBusinessReportsGetManagerReportUsersActivityRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ManagerReportOfUserActivityResponseModel>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/reports/usersActivities`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get report of user activity
     */
    async crmCustomersBusinessReportsGetManagerReportUsersActivity(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ManagerReportOfUserActivityResponseModel>> {
        const response = await this.crmCustomersBusinessReportsGetManagerReportUsersActivityRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get installation information. States parameter is optional and can be any comma separated combination of active,inactive,deleted to filter for customer state.
     */
    async crmCustomersBusinessReportsGetProductReportsRaw(requestParameters: CrmCustomersBusinessReportsGetProductReportsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BusinessReportDataResponseModel>> {
        if (requestParameters.aggregation === null || requestParameters.aggregation === undefined) {
            throw new runtime.RequiredError('aggregation','Required parameter requestParameters.aggregation was null or undefined when calling crmCustomersBusinessReportsGetProductReports.');
        }

        if (requestParameters.fromDate === null || requestParameters.fromDate === undefined) {
            throw new runtime.RequiredError('fromDate','Required parameter requestParameters.fromDate was null or undefined when calling crmCustomersBusinessReportsGetProductReports.');
        }

        if (requestParameters.toDate === null || requestParameters.toDate === undefined) {
            throw new runtime.RequiredError('toDate','Required parameter requestParameters.toDate was null or undefined when calling crmCustomersBusinessReportsGetProductReports.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/reports/product/{aggregation}/{fromDate}/{toDate}`.replace(`{${"aggregation"}}`, encodeURIComponent(String(requestParameters.aggregation))).replace(`{${"fromDate"}}`, encodeURIComponent(String(requestParameters.fromDate))).replace(`{${"toDate"}}`, encodeURIComponent(String(requestParameters.toDate))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get installation information. States parameter is optional and can be any comma separated combination of active,inactive,deleted to filter for customer state.
     */
    async crmCustomersBusinessReportsGetProductReports(requestParameters: CrmCustomersBusinessReportsGetProductReportsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BusinessReportDataResponseModel> {
        const response = await this.crmCustomersBusinessReportsGetProductReportsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get installation information. States parameter is optional and can be any comma separated combination of active,inactive,deleted to filter for customer state.
     */
    async crmCustomersBusinessReportsGetProductReportsByAggregationFromDateToDateStatesRaw(requestParameters: CrmCustomersBusinessReportsGetProductReportsByAggregationFromDateToDateStatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BusinessReportDataResponseModel>> {
        if (requestParameters.aggregation === null || requestParameters.aggregation === undefined) {
            throw new runtime.RequiredError('aggregation','Required parameter requestParameters.aggregation was null or undefined when calling crmCustomersBusinessReportsGetProductReportsByAggregationFromDateToDateStates.');
        }

        if (requestParameters.fromDate === null || requestParameters.fromDate === undefined) {
            throw new runtime.RequiredError('fromDate','Required parameter requestParameters.fromDate was null or undefined when calling crmCustomersBusinessReportsGetProductReportsByAggregationFromDateToDateStates.');
        }

        if (requestParameters.toDate === null || requestParameters.toDate === undefined) {
            throw new runtime.RequiredError('toDate','Required parameter requestParameters.toDate was null or undefined when calling crmCustomersBusinessReportsGetProductReportsByAggregationFromDateToDateStates.');
        }

        if (requestParameters.states === null || requestParameters.states === undefined) {
            throw new runtime.RequiredError('states','Required parameter requestParameters.states was null or undefined when calling crmCustomersBusinessReportsGetProductReportsByAggregationFromDateToDateStates.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/reports/product/{aggregation}/{fromDate}/{toDate}/{states}`.replace(`{${"aggregation"}}`, encodeURIComponent(String(requestParameters.aggregation))).replace(`{${"fromDate"}}`, encodeURIComponent(String(requestParameters.fromDate))).replace(`{${"toDate"}}`, encodeURIComponent(String(requestParameters.toDate))).replace(`{${"states"}}`, encodeURIComponent(String(requestParameters.states))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get installation information. States parameter is optional and can be any comma separated combination of active,inactive,deleted to filter for customer state.
     */
    async crmCustomersBusinessReportsGetProductReportsByAggregationFromDateToDateStates(requestParameters: CrmCustomersBusinessReportsGetProductReportsByAggregationFromDateToDateStatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BusinessReportDataResponseModel> {
        const response = await this.crmCustomersBusinessReportsGetProductReportsByAggregationFromDateToDateStatesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get status regarding CIAM and activity behavior of customers
     */
    async crmCustomersBusinessReportsGetStatusReportsRaw(requestParameters: CrmCustomersBusinessReportsGetStatusReportsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BusinessReportDataResponseModel>> {
        if (requestParameters.aggregation === null || requestParameters.aggregation === undefined) {
            throw new runtime.RequiredError('aggregation','Required parameter requestParameters.aggregation was null or undefined when calling crmCustomersBusinessReportsGetStatusReports.');
        }

        if (requestParameters.fromDate === null || requestParameters.fromDate === undefined) {
            throw new runtime.RequiredError('fromDate','Required parameter requestParameters.fromDate was null or undefined when calling crmCustomersBusinessReportsGetStatusReports.');
        }

        if (requestParameters.toDate === null || requestParameters.toDate === undefined) {
            throw new runtime.RequiredError('toDate','Required parameter requestParameters.toDate was null or undefined when calling crmCustomersBusinessReportsGetStatusReports.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/reports/status/{aggregation}/{fromDate}/{toDate}`.replace(`{${"aggregation"}}`, encodeURIComponent(String(requestParameters.aggregation))).replace(`{${"fromDate"}}`, encodeURIComponent(String(requestParameters.fromDate))).replace(`{${"toDate"}}`, encodeURIComponent(String(requestParameters.toDate))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get status regarding CIAM and activity behavior of customers
     */
    async crmCustomersBusinessReportsGetStatusReports(requestParameters: CrmCustomersBusinessReportsGetStatusReportsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BusinessReportDataResponseModel> {
        const response = await this.crmCustomersBusinessReportsGetStatusReportsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get country and region specific KPI`s of your customers
     */
    async crmCustomersBusinessReportsGetTenantReportsRaw(requestParameters: CrmCustomersBusinessReportsGetTenantReportsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BusinessReportDataResponseModel>> {
        if (requestParameters.aggregation === null || requestParameters.aggregation === undefined) {
            throw new runtime.RequiredError('aggregation','Required parameter requestParameters.aggregation was null or undefined when calling crmCustomersBusinessReportsGetTenantReports.');
        }

        if (requestParameters.fromDate === null || requestParameters.fromDate === undefined) {
            throw new runtime.RequiredError('fromDate','Required parameter requestParameters.fromDate was null or undefined when calling crmCustomersBusinessReportsGetTenantReports.');
        }

        if (requestParameters.toDate === null || requestParameters.toDate === undefined) {
            throw new runtime.RequiredError('toDate','Required parameter requestParameters.toDate was null or undefined when calling crmCustomersBusinessReportsGetTenantReports.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/reports/tenant/{aggregation}/{fromDate}/{toDate}`.replace(`{${"aggregation"}}`, encodeURIComponent(String(requestParameters.aggregation))).replace(`{${"fromDate"}}`, encodeURIComponent(String(requestParameters.fromDate))).replace(`{${"toDate"}}`, encodeURIComponent(String(requestParameters.toDate))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get country and region specific KPI`s of your customers
     */
    async crmCustomersBusinessReportsGetTenantReports(requestParameters: CrmCustomersBusinessReportsGetTenantReportsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BusinessReportDataResponseModel> {
        const response = await this.crmCustomersBusinessReportsGetTenantReportsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get vendor related specific KPI`s of your customers. States parameter is optional and can be any comma separated combination of active,inactive,deleted to filter for customer state.
     */
    async crmCustomersBusinessReportsGetVendorReportsRaw(requestParameters: CrmCustomersBusinessReportsGetVendorReportsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BusinessReportDataResponseModel>> {
        if (requestParameters.aggregation === null || requestParameters.aggregation === undefined) {
            throw new runtime.RequiredError('aggregation','Required parameter requestParameters.aggregation was null or undefined when calling crmCustomersBusinessReportsGetVendorReports.');
        }

        if (requestParameters.fromDate === null || requestParameters.fromDate === undefined) {
            throw new runtime.RequiredError('fromDate','Required parameter requestParameters.fromDate was null or undefined when calling crmCustomersBusinessReportsGetVendorReports.');
        }

        if (requestParameters.toDate === null || requestParameters.toDate === undefined) {
            throw new runtime.RequiredError('toDate','Required parameter requestParameters.toDate was null or undefined when calling crmCustomersBusinessReportsGetVendorReports.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/reports/vendor/{aggregation}/{fromDate}/{toDate}`.replace(`{${"aggregation"}}`, encodeURIComponent(String(requestParameters.aggregation))).replace(`{${"fromDate"}}`, encodeURIComponent(String(requestParameters.fromDate))).replace(`{${"toDate"}}`, encodeURIComponent(String(requestParameters.toDate))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get vendor related specific KPI`s of your customers. States parameter is optional and can be any comma separated combination of active,inactive,deleted to filter for customer state.
     */
    async crmCustomersBusinessReportsGetVendorReports(requestParameters: CrmCustomersBusinessReportsGetVendorReportsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BusinessReportDataResponseModel> {
        const response = await this.crmCustomersBusinessReportsGetVendorReportsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get vendor related specific KPI`s of your customers. States parameter is optional and can be any comma separated combination of active,inactive,deleted to filter for customer state.
     */
    async crmCustomersBusinessReportsGetVendorReportsByAggregationFromDateToDateStatesRaw(requestParameters: CrmCustomersBusinessReportsGetVendorReportsByAggregationFromDateToDateStatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BusinessReportDataResponseModel>> {
        if (requestParameters.aggregation === null || requestParameters.aggregation === undefined) {
            throw new runtime.RequiredError('aggregation','Required parameter requestParameters.aggregation was null or undefined when calling crmCustomersBusinessReportsGetVendorReportsByAggregationFromDateToDateStates.');
        }

        if (requestParameters.fromDate === null || requestParameters.fromDate === undefined) {
            throw new runtime.RequiredError('fromDate','Required parameter requestParameters.fromDate was null or undefined when calling crmCustomersBusinessReportsGetVendorReportsByAggregationFromDateToDateStates.');
        }

        if (requestParameters.toDate === null || requestParameters.toDate === undefined) {
            throw new runtime.RequiredError('toDate','Required parameter requestParameters.toDate was null or undefined when calling crmCustomersBusinessReportsGetVendorReportsByAggregationFromDateToDateStates.');
        }

        if (requestParameters.states === null || requestParameters.states === undefined) {
            throw new runtime.RequiredError('states','Required parameter requestParameters.states was null or undefined when calling crmCustomersBusinessReportsGetVendorReportsByAggregationFromDateToDateStates.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/reports/vendor/{aggregation}/{fromDate}/{toDate}/{states}`.replace(`{${"aggregation"}}`, encodeURIComponent(String(requestParameters.aggregation))).replace(`{${"fromDate"}}`, encodeURIComponent(String(requestParameters.fromDate))).replace(`{${"toDate"}}`, encodeURIComponent(String(requestParameters.toDate))).replace(`{${"states"}}`, encodeURIComponent(String(requestParameters.states))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get vendor related specific KPI`s of your customers. States parameter is optional and can be any comma separated combination of active,inactive,deleted to filter for customer state.
     */
    async crmCustomersBusinessReportsGetVendorReportsByAggregationFromDateToDateStates(requestParameters: CrmCustomersBusinessReportsGetVendorReportsByAggregationFromDateToDateStatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BusinessReportDataResponseModel> {
        const response = await this.crmCustomersBusinessReportsGetVendorReportsByAggregationFromDateToDateStatesRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
