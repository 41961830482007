export enum LanguageCode {
    ENGLISH = 'en',
    DEUTSCH = 'de',
    ITALIANO = 'it',
    MAGYAR = 'hu',
    POLSKI = 'pl',
}

export enum CountryCode {
    ENGLISH = 'GB',
    DEUTSCH = 'DE',
    ITALIANO = 'IT',
    MAGYAR = 'HU',
    POLSKI = 'PL',
}
