import { LinearProgress } from '@mui/material';

import { WithErrorProps } from '@src/components/higher-order/with-error';
import { WithLoaderProps } from '@src/components/higher-order/with-loader';

import { GenericError } from '@components';

import styles from '@src/components/higher-order/with-error-and-loader/index.module.scss';

type Props = WithErrorProps & WithLoaderProps;

export const WithErrorAndLoader: React.FC<Readonly<Props>> = ({
    error,
    loading,
    children,
    className,
    errorProps,
}: Props) => {
    if (error) {
        return className ? (
            <div className={className}>
                <GenericError {...errorProps} />
            </div>
        ) : (
            <GenericError {...errorProps} />
        );
    }

    return className ? (
        <div className={className}>
            {loading ? (
                <LinearProgress
                    color="primary"
                    sx={{ margin: '-1rem -1rem 0 -1rem' }}
                />
            ) : null}
            <>{children}</>
        </div>
    ) : (
        <>
            {loading ? (
                <LinearProgress color="primary" className={styles.loader} />
            ) : null}
            <>{children}</>
        </>
    );
};
