import { Scope } from '@tools/enums';

export const settingsReadScope = [
    Scope.SYSTEM_SETTINGS_READ,
    Scope.ACCOUNT_API_ACCESS_READ,
    Scope.NOTIFICATIONS_READ,
];

export const featuresReadScope = [Scope.SUPPORTED_FEATURES_READ];

export const cmsReadScope = [Scope.CMS_CONTENT_READ, Scope.CMS_SETTINGS_READ];

export const customersWriteScope = [Scope.CUSTOMERS_WRITE];

export const customersMultifactorReadScope = [Scope.CUSTOMERS_MULTIFACTOR_READ];

export const customersAddressWriteScope = [Scope.CUSTOMERS_ADDRESS_WRITE];

export const customersDelete = [Scope.CUSTOMERS_DELETE];
