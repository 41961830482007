/* tslint:disable */
/* eslint-disable */
/**
 * E.ON Home Admin Portal - API
 * # Introduction    This API provides access to admin and customer management.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: sebastian.eggers@eon.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Forbidden,
  SiteHealthResponse,
  SiteHealthSummaryResponse,
  SitesHealthRequestBody,
  Unauthorized,
} from '../models';

export interface CrmCustomersHealthGetCustomerSiteHealthRequest {
    customerId: string;
}

export interface CrmCustomersHealthGetSitesHealthRequest {
    sitesHealthRequestBody: SitesHealthRequestBody;
}

/**
 * 
 */
export class CRMCustomersHealthApi extends runtime.BaseAPI {

    /**
     * Get customer site health
     */
    async crmCustomersHealthGetCustomerSiteHealthRaw(requestParameters: CrmCustomersHealthGetCustomerSiteHealthRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SiteHealthResponse>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling crmCustomersHealthGetCustomerSiteHealth.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/customers/{customerId}/health/site`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get customer site health
     */
    async crmCustomersHealthGetCustomerSiteHealth(requestParameters: CrmCustomersHealthGetCustomerSiteHealthRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SiteHealthResponse> {
        const response = await this.crmCustomersHealthGetCustomerSiteHealthRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the installation health of all provided customer ids
     */
    async crmCustomersHealthGetSitesHealthRaw(requestParameters: CrmCustomersHealthGetSitesHealthRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SiteHealthSummaryResponse>>> {
        if (requestParameters.sitesHealthRequestBody === null || requestParameters.sitesHealthRequestBody === undefined) {
            throw new runtime.RequiredError('sitesHealthRequestBody','Required parameter requestParameters.sitesHealthRequestBody was null or undefined when calling crmCustomersHealthGetSitesHealth.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/customers/health/sites`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.sitesHealthRequestBody,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get the installation health of all provided customer ids
     */
    async crmCustomersHealthGetSitesHealth(requestParameters: CrmCustomersHealthGetSitesHealthRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SiteHealthSummaryResponse>> {
        const response = await this.crmCustomersHealthGetSitesHealthRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
