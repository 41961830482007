import { FC } from 'react';

import { I18nextProvider } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';

import { createRoot } from 'react-dom/client';

import CssBaseline from '@mui/material/CssBaseline';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { App } from '@src/components/app';
import theme from '@tools/utils/theme';

import {
    ToastNotificationRenderer,
    ToastNotificationsProvider,
} from '@components/notifications';
import { AuthProvider } from '@context/auth-provider';
import { DialogContextProvider } from '@context/dialog-provider';
import { FetchInterceptor } from '@context/fetch-interceptor';
import { StatusContextProvider } from '@context/status-provider';
import { UserContextProvider } from '@context/user-provider';
import i18n from '@src/i18n';
import * as serviceWorkerRegistration from '@src/service-worker-registration';

import '@src/index.scss';

declare module 'react' {
    interface CSSProperties {
        fill?: string;
    }
}

declare global {
    namespace NodeJS {
        interface ProcessEnv {
            BRANCH: 'develop' | 'staging' | 'master';
            NODE_ENV: 'development' | 'production';
            API_URL_DEFAULT: string;
        }
    }
}

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            refetchOnMount: true,
            refetchOnReconnect: true,
            cacheTime: 1000 * 60 * 10, // The BE also caches the data for 10 min
            retry: false,
        },
    },
});

const node = document.getElementById('root') || document.createElement('div');
const root = createRoot(node);

const renderRoot = (Application: FC): void => {
    root.render(
        (
            <I18nextProvider i18n={i18n}>
                <QueryClientProvider client={queryClient}>
                    <ThemeProvider theme={theme}>
                        <ToastNotificationsProvider>
                            <ToastNotificationRenderer />
                            <BrowserRouter>
                                <AuthProvider>
                                    <UserContextProvider>
                                        <StatusContextProvider>
                                            <FetchInterceptor />
                                            <CssBaseline />
                                            <StyledEngineProvider injectFirst>
                                                <DialogContextProvider>
                                                    <Application />
                                                </DialogContextProvider>
                                            </StyledEngineProvider>
                                        </StatusContextProvider>
                                    </UserContextProvider>
                                </AuthProvider>
                            </BrowserRouter>
                        </ToastNotificationsProvider>
                    </ThemeProvider>
                    <ReactQueryDevtools initialIsOpen={false} />
                </QueryClientProvider>
            </I18nextProvider>
        ) as any,
    );
};

renderRoot(App);

serviceWorkerRegistration.register();

// TODO:
// - connect to sentry: EONFEH-15361
