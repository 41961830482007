import { FC, ReactNode, useCallback } from 'react';

import { Navigate, Route, RouteProps, Routes, useLocation } from 'react-router';
import { createSearchParams, useSearchParams } from 'react-router-dom';

import { LoadableComponent } from '@loadable/component';
import { Box } from '@mui/material';

import {
    ACCOUNT_ROUTES,
    Routes as AppRoutes,
    CMS_ROUTES,
    CMS_V2_ROUTES,
    CUSTOMER_ID_ROUTES,
    CUSTOMERS_ROUTES,
    DASHBOARD_ROUTES,
    FEATURE_GROUPS_ROUTES,
    INTEGRATION_SETTINGS_ROUTES,
    PROFILE_ROUTES,
    REPORTS_ROUTES,
    RouteData,
} from '@api/tools';

import * as Loadables from '@src/components/app/loadables';
import { checkForScopes } from '@tools/utils/scopes';

import Page from '@components/page';
import useAuthContext from '@context/auth-provider';
import { TARGET_ROUTE } from '@tools/constants';
import { Scope } from '@tools/enums';

type PrivateRouteProps = RouteProps & {
    requiredScopes?: Scope[];
};

const LoginRedirectWrapper = () => {
    const [searchParams] = useSearchParams();
    const location = useLocation();

    const path = {
        pathname: AppRoutes.LOGIN,
        search: `?${createSearchParams(searchParams)}`,
    };

    /**
     * Using useLocalStorage hook for setting the target route
     * results in exceeding max depth update limit, so instead
     * use localStorage.setItem directly to store the target route
     */
    if (location.pathname !== '/oauth') {
        localStorage.setItem(TARGET_ROUTE, location.pathname);
    }

    return <Navigate to={path} replace />;
};

export const App: FC = () => {
    const { isLoggedIn } = useAuthContext();

    const renderPrivateRoute = useCallback(
        (props: PrivateRouteProps) => {
            const { requiredScopes } = props;
            const allowAccess = requiredScopes?.length
                ? checkForScopes(requiredScopes)
                : true;

            if (isLoggedIn)
                return allowAccess ? (
                    <Route {...props} />
                ) : (
                    // When an user is logged in and doesn't have the permissions to access a page
                    // we should redirect them to the dashboard page
                    <Route
                        path={props.path}
                        element={<Navigate to={AppRoutes.DASHBOARD} replace />}
                    />
                );

            // Unauthenticated users flow is handled in fetch interceptor
            return null;
        },
        [isLoggedIn],
    );

    const setActiveRoute = useCallback(
        (route: string, defaultRoute: string, mainRoute?: string) =>
            !!mainRoute && route === mainRoute ? defaultRoute : route,
        [],
    );

    const renderRoutes = useCallback(
        (
            data: RouteData[],
            Component: LoadableComponent<any>,
            mainRoute?: AppRoutes,
        ): ReactNode[] | null => {
            const routesWithPermissions = data.filter(({ scopes }) =>
                scopes.length > 0 ? checkForScopes(scopes) : true,
            );

            /**
             * In some cases, where user may not have all permissions granted
             * to access all of the pages, e.g. user has partial access to "Integration settings"
             * we need to define which should be the default screen we show them
             * when navigating from the side navigation.
             *
             * This is why we need to use the "mainRoute" in the navigation as a URL
             * to a certain screen (with tabs), and then here we handle the redirect to the correct screen/tab
             * for which the user has permissions to access.
             */
            const defaultRoute =
                !!routesWithPermissions.length &&
                routesWithPermissions.filter(
                    (route) => route.path !== mainRoute,
                )?.[0];

            return data.map(({ path, scopes, isPublic }) => {
                const props = {
                    key: path,
                    path,
                    element:
                        path === mainRoute && !!defaultRoute ? (
                            <Navigate to={defaultRoute.path} replace />
                        ) : (
                            <Component
                                active={setActiveRoute(
                                    path,
                                    (defaultRoute as RouteData)?.path || path,
                                    mainRoute,
                                )}
                            />
                        ),
                    requiredScopes: scopes,
                };

                return isPublic ? (
                    <Route {...props} />
                ) : (
                    renderPrivateRoute(props)
                );
            });
        },
        [renderPrivateRoute, setActiveRoute],
    );

    return (
        <Box style={{ height: '100%' }}>
            <Page>
                <Loadables.DataStore />
                <Routes>
                    {!isLoggedIn && (
                        <Route path="*" element={<LoginRedirectWrapper />} />
                    )}
                    <Route path="/oauth" element={<LoginRedirectWrapper />} />
                    {/** DASHBOARD */}
                    {renderRoutes(DASHBOARD_ROUTES, Loadables.DashboardPage)}
                    {/** BUSINESS REPORTS */}
                    {renderRoutes(REPORTS_ROUTES, Loadables.ReportsPage)}
                    {
                        /** PROFILE */
                        renderRoutes(
                            PROFILE_ROUTES,
                            Loadables.ProfilePage,
                            AppRoutes.PROFILE,
                        )
                    }
                    {
                        /** ACCOUNT */
                        renderRoutes(
                            ACCOUNT_ROUTES,
                            Loadables.AccountPage,
                            AppRoutes.ACCOUNT,
                        )
                    }
                    {
                        /** CUSTOMERS */
                        renderRoutes(
                            CUSTOMERS_ROUTES,
                            Loadables.CustomersPage,
                            AppRoutes.CUSTOMERS,
                        )
                    }
                    {
                        /** CUSTOMER_ID */
                        renderRoutes(
                            CUSTOMER_ID_ROUTES,
                            Loadables.CustomerPage,
                            AppRoutes.CUSTOMER_ID,
                        )
                    }
                    {
                        /** INTEGRATION SETTINGS */
                        renderRoutes(
                            INTEGRATION_SETTINGS_ROUTES,
                            Loadables.IntegrationSettingsPage,
                            AppRoutes.INTEGRATION_SETTINGS,
                        )
                    }
                    {
                        /** FEATURE GROUPS */
                        renderRoutes(
                            FEATURE_GROUPS_ROUTES,
                            Loadables.FeatureGroupsPage,
                            AppRoutes.FEATURE_GROUPS,
                        )
                    }
                    {
                        /** CMS */
                        renderRoutes(
                            CMS_ROUTES,
                            Loadables.CMSPage,
                            AppRoutes.CMS,
                        )
                    }
                    ,
                    {
                        /** CMS V2*/
                        renderRoutes(
                            CMS_V2_ROUTES,
                            Loadables.CMSV2Page,
                            AppRoutes.CMS_V2,
                        )
                    }
                    {/**
                     * When there is no match within defined routes
                     */}
                    <Route path="*" element={<Loadables.NotFound />} />
                    <Route
                        path={AppRoutes.BASE}
                        element={<Navigate to={AppRoutes.DASHBOARD} replace />}
                    />
                    <Route
                        path={AppRoutes.LOGIN}
                        element={<Loadables.LoginPage />}
                    />
                </Routes>
            </Page>
        </Box>
    );
};
