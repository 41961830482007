import {
    createContext,
    Dispatch,
    FC,
    PropsWithChildren,
    SetStateAction,
    useContext,
    useMemo,
    useState,
} from 'react';

import { PossiblyUndefined } from '@tools/types';

export interface IDialogContext {
    isAddMember: boolean;
    setIsAddMember: Dispatch<SetStateAction<boolean>>;
    isDialogOpen: boolean;
    setIsDialogOpen: Dispatch<SetStateAction<boolean>>;
}

const DialogContext =
    createContext<PossiblyUndefined<IDialogContext>>(undefined);

export const DialogContextProvider: FC<PropsWithChildren> = ({ children }) => {
    const [isAddMember, setIsAddMember] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const value: IDialogContext = useMemo(
        () => ({
            isDialogOpen,
            setIsDialogOpen,
            isAddMember,
            setIsAddMember,
        }),
        [isAddMember, isDialogOpen],
    );

    return (
        <DialogContext.Provider value={value}>
            {children}
        </DialogContext.Provider>
    );
};

const useDialogContext = () => {
    const context = useContext(DialogContext);
    if (context === undefined) {
        throw new Error(
            '`useDialogContext` must be used within `DialogContextProvider`',
        );
    }

    return context;
};

export default useDialogContext;
