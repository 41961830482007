import { Configuration } from '@swagger-http';

import { API_URL_DEFAULT } from '@tools/constants';

export const createRequestConfiguration = (
    basePath = API_URL_DEFAULT,
    accessToken?: string,
): Configuration =>
    new Configuration({
        basePath,
        // prettier-ignore
        ...(accessToken ? { headers: { Authorization: `Bearer ${accessToken}` } } : {}),
    });
