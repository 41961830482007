/* tslint:disable */
/* eslint-disable */
/**
 * E.ON Home Admin Portal - API
 * # Introduction    This API provides access to admin and customer management.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: sebastian.eggers@eon.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CustomerTypeModel,
  Forbidden,
  IoTVendorCategoryResponseModel,
  IoTVendorFilterModel,
  IoTVendorPostModel,
  IoTVendorPutModel,
  IoTVendorResponseModel,
  IoTVendorResponseSimpleModel,
  IoTVendorTenantPutModel,
  IoTVendorTenantsResponseModel,
  NotFound,
  TenantAllocationsFilterModel,
  TenantAllocationsResponseModel,
  Unauthorized,
} from '../models';

export interface SettingsCreateVendorRequest {
    ioTVendorPostModel: IoTVendorPostModel;
}

export interface SettingsDeleteVendorRequest {
    vendorId: string;
    replacementVendorId: string;
}

export interface SettingsGetTenantAllocationsByFilterRequest {
    filter?: TenantAllocationsFilterModel;
}

export interface SettingsGetTenantVendorAllocationRequest {
    vendorId: string;
}

export interface SettingsGetVendorsRequest {
    filter?: IoTVendorFilterModel;
}

export interface SettingsTenantVendorAllocateRequest {
    vendorId: string;
    ioTVendorTenantPutModel: IoTVendorTenantPutModel;
}

export interface SettingsUpdateVendorRequest {
    vendorId: string;
    ioTVendorPutModel: IoTVendorPutModel;
}

/**
 * 
 */
export class SettingsApi extends runtime.BaseAPI {

    /**
     * Add a vendor
     */
    async settingsCreateVendorRaw(requestParameters: SettingsCreateVendorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IoTVendorResponseModel>> {
        if (requestParameters.ioTVendorPostModel === null || requestParameters.ioTVendorPostModel === undefined) {
            throw new runtime.RequiredError('ioTVendorPostModel','Required parameter requestParameters.ioTVendorPostModel was null or undefined when calling settingsCreateVendor.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/settings/iot-vendors`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.ioTVendorPostModel,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Add a vendor
     */
    async settingsCreateVendor(requestParameters: SettingsCreateVendorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IoTVendorResponseModel> {
        const response = await this.settingsCreateVendorRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Remove a vendor
     */
    async settingsDeleteVendorRaw(requestParameters: SettingsDeleteVendorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.vendorId === null || requestParameters.vendorId === undefined) {
            throw new runtime.RequiredError('vendorId','Required parameter requestParameters.vendorId was null or undefined when calling settingsDeleteVendor.');
        }

        if (requestParameters.replacementVendorId === null || requestParameters.replacementVendorId === undefined) {
            throw new runtime.RequiredError('replacementVendorId','Required parameter requestParameters.replacementVendorId was null or undefined when calling settingsDeleteVendor.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/settings/iot-vendors/{vendorId}/{replacementVendorId}`.replace(`{${"vendorId"}}`, encodeURIComponent(String(requestParameters.vendorId))).replace(`{${"replacementVendorId"}}`, encodeURIComponent(String(requestParameters.replacementVendorId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Remove a vendor
     */
    async settingsDeleteVendor(requestParameters: SettingsDeleteVendorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.settingsDeleteVendorRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get list of customer types
     */
    async settingsGetCustomerTypesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CustomerTypeModel>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/settings/customer-types`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get list of customer types
     */
    async settingsGetCustomerTypes(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CustomerTypeModel>> {
        const response = await this.settingsGetCustomerTypesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get list of supported countries
     */
    async settingsGetSupportedCountriesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<IoTVendorResponseModel>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/settings/countries`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get list of supported countries
     */
    async settingsGetSupportedCountries(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<IoTVendorResponseModel>> {
        const response = await this.settingsGetSupportedCountriesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get tenant allocations
     */
    async settingsGetTenantAllocationsByFilterRaw(requestParameters: SettingsGetTenantAllocationsByFilterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TenantAllocationsResponseModel>>> {
        const queryParameters: any = {};

        if (requestParameters.filter !== undefined) {
            queryParameters['filter'] = requestParameters.filter;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/settings/tenant-allocations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get tenant allocations
     */
    async settingsGetTenantAllocationsByFilter(requestParameters: SettingsGetTenantAllocationsByFilterRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TenantAllocationsResponseModel>> {
        const response = await this.settingsGetTenantAllocationsByFilterRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Vendor\'s tenant allocation
     */
    async settingsGetTenantVendorAllocationRaw(requestParameters: SettingsGetTenantVendorAllocationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IoTVendorTenantsResponseModel>> {
        if (requestParameters.vendorId === null || requestParameters.vendorId === undefined) {
            throw new runtime.RequiredError('vendorId','Required parameter requestParameters.vendorId was null or undefined when calling settingsGetTenantVendorAllocation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/settings/iot-vendors/{vendorId}/tenant-allocation`.replace(`{${"vendorId"}}`, encodeURIComponent(String(requestParameters.vendorId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get Vendor\'s tenant allocation
     */
    async settingsGetTenantVendorAllocation(requestParameters: SettingsGetTenantVendorAllocationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IoTVendorTenantsResponseModel> {
        const response = await this.settingsGetTenantVendorAllocationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get list of IoT vendors
     */
    async settingsGetVendorsRaw(requestParameters: SettingsGetVendorsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<IoTVendorResponseSimpleModel>>> {
        const queryParameters: any = {};

        if (requestParameters.filter !== undefined) {
            queryParameters['filter'] = requestParameters.filter;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/settings/iot-vendors`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get list of IoT vendors
     */
    async settingsGetVendors(requestParameters: SettingsGetVendorsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<IoTVendorResponseSimpleModel>> {
        const response = await this.settingsGetVendorsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get list of IoT vendors available to being added, regardless their existence
     */
    async settingsGetVendorsCategoriesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<IoTVendorCategoryResponseModel>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/settings/iot-vendors/categories`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get list of IoT vendors available to being added, regardless their existence
     */
    async settingsGetVendorsCategories(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<IoTVendorCategoryResponseModel>> {
        const response = await this.settingsGetVendorsCategoriesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get list of IoT vendors with health checks
     */
    async settingsGetVendorsHealthRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<IoTVendorResponseModel>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/settings/iot-vendors/health`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get list of IoT vendors with health checks
     */
    async settingsGetVendorsHealth(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<IoTVendorResponseModel>> {
        const response = await this.settingsGetVendorsHealthRaw(initOverrides);
        return await response.value();
    }

    /**
     * Associate tenants to vendor
     */
    async settingsTenantVendorAllocateRaw(requestParameters: SettingsTenantVendorAllocateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IoTVendorTenantsResponseModel>> {
        if (requestParameters.vendorId === null || requestParameters.vendorId === undefined) {
            throw new runtime.RequiredError('vendorId','Required parameter requestParameters.vendorId was null or undefined when calling settingsTenantVendorAllocate.');
        }

        if (requestParameters.ioTVendorTenantPutModel === null || requestParameters.ioTVendorTenantPutModel === undefined) {
            throw new runtime.RequiredError('ioTVendorTenantPutModel','Required parameter requestParameters.ioTVendorTenantPutModel was null or undefined when calling settingsTenantVendorAllocate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/settings/iot-vendors/{vendorId}/tenant-allocations`.replace(`{${"vendorId"}}`, encodeURIComponent(String(requestParameters.vendorId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.ioTVendorTenantPutModel,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Associate tenants to vendor
     */
    async settingsTenantVendorAllocate(requestParameters: SettingsTenantVendorAllocateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IoTVendorTenantsResponseModel> {
        const response = await this.settingsTenantVendorAllocateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a vendor
     */
    async settingsUpdateVendorRaw(requestParameters: SettingsUpdateVendorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IoTVendorPutModel>> {
        if (requestParameters.vendorId === null || requestParameters.vendorId === undefined) {
            throw new runtime.RequiredError('vendorId','Required parameter requestParameters.vendorId was null or undefined when calling settingsUpdateVendor.');
        }

        if (requestParameters.ioTVendorPutModel === null || requestParameters.ioTVendorPutModel === undefined) {
            throw new runtime.RequiredError('ioTVendorPutModel','Required parameter requestParameters.ioTVendorPutModel was null or undefined when calling settingsUpdateVendor.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/settings/iot-vendors/{vendorId}`.replace(`{${"vendorId"}}`, encodeURIComponent(String(requestParameters.vendorId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.ioTVendorPutModel,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Update a vendor
     */
    async settingsUpdateVendor(requestParameters: SettingsUpdateVendorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IoTVendorPutModel> {
        const response = await this.settingsUpdateVendorRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
