import {
    createContext,
    FC,
    PropsWithChildren,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';

import { useLocation, useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import { Routes } from '@api/tools';
import {
    AuthenticationResponseModel,
    AuthorizationResponseModel,
} from '@swagger-http';

import {
    useAuthenticationLinkQuery,
    useMutateSignIn,
    useMutateSignInMultiFactor,
    useMutateSignOut,
} from '@queries/authentication';

import useLocalStorage from '@hooks/use-local-storage';
import {
    CODE,
    SCOPES,
    SESSION,
    STATE,
    TARGET_ROUTE,
    TEMP_SESSION,
} from '@tools/constants';

export interface IAuthContext {
    submit: () => void;
    signOut: () => Promise<void>;
    onTokenSubmit: (token: string) => Promise<void>;
    hasCode: boolean;
    hasMultiFactorAuth: boolean;
    isLoading: boolean;
    error: IAuthApiError | null;
    isLoggedIn: boolean;
}

export interface IAuthApiError {
    name?: string;
    message?: string;
    description?: string;
    status: string | number;
}

export interface IAuthSession {
    accessToken: string;
    refreshToken: string;
    isAuthenticated: boolean;
    multiFactorAuthentication: boolean;
    scopes: AuthorizationResponseModel[];
}

export const setScopesInLocalStorage = (scopes: string) => {
    if (!scopes) {
        return;
    }

    localStorage.setItem(SCOPES, JSON.stringify(scopes.split(',')));
};
const ErrorMessageInvalidMfaToken = 'Invalid MFA Token';

const generateAuthSessionObject = (
    response: AuthenticationResponseModel,
): IAuthSession => ({
    accessToken: response.access_token ?? '',
    refreshToken: response.refresh_token ?? '',
    scopes: response.scopes ?? ([] as AuthorizationResponseModel[]),
    isAuthenticated: !!response,
    multiFactorAuthentication: !!response.multiFactorAuthentication,
});

const AuthContext = createContext<IAuthContext | undefined>(undefined);

export const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams] = useSearchParams();

    const [signInResponse, setSignInResponse] =
        useState<AuthenticationResponseModel | null>(null);

    const {
        setStorageValue: setAuthSessionStoredValue,
        getStorageValue: getAuthSessionValue,
    } = useLocalStorage<IAuthSession | null>(SESSION, null);

    const { setStorageValue: setTempAuthStoredValue } =
        useLocalStorage<IAuthSession | null>(TEMP_SESSION, null);

    const authorization = `Basic ${btoa(
        `${searchParams.get(CODE)}:${searchParams.get(STATE)}`,
    )}`;

    const {
        mutateAsync: mutateSignIn,
        error: errorOnLogin,
        isLoading: isLoadingOnLogin,
    } = useMutateSignIn(authorization);

    const {
        mutateAsync: mutateSignOut,
        error: errorOnLogout,
        isLoading: isLoadingOnLogout,
    } = useMutateSignOut();

    const {
        mutateAsync: mutateSignInMultiFactor,
        error: errorOnMultifactor,
        isLoading: isLoadingMultiFactor,
    } = useMutateSignInMultiFactor();

    const signOut = useCallback(async () => {
        try {
            await mutateSignOut();
        } catch (error) {
            console.error('SignOut:error', error);
        } finally {
            // clear session and refresh
            setSignInResponse(() => {
                // same behavior we have in old src/modules/@hems/data-services/redux/middlewares/authorization.observer.ts
                const hasMfaError =
                    (errorOnMultifactor as IAuthApiError)?.message ===
                    ErrorMessageInvalidMfaToken;
                if (!hasMfaError) {
                    const targetRoute = localStorage.getItem(TARGET_ROUTE);
                    localStorage.clear();
                    if (targetRoute) {
                        localStorage.setItem(TARGET_ROUTE, targetRoute);
                    }
                }
                return null;
            });

            navigate(Routes.LOGIN);
        }
    }, [errorOnMultifactor, mutateSignOut, navigate]);

    const { data: authLink, isLoading: isLoadingAuthResourceLink } =
        useAuthenticationLinkQuery();

    const submit = useCallback(() => {
        if (!isLoadingAuthResourceLink && authLink?.uri) {
            window.location.href = authLink.uri;
        }
    }, [isLoadingAuthResourceLink, authLink?.uri]);

    const signIn = useCallback(async () => {
        try {
            const result = await mutateSignIn();

            setSignInResponse(result);
        } catch (error) {
            console.error('signIn:error', error);
        }
    }, [mutateSignIn]);

    const handleNavigate = useCallback(() => {
        const url = localStorage.getItem(TARGET_ROUTE);
        if (url) localStorage.removeItem(TARGET_ROUTE);

        navigate(url || Routes.DASHBOARD);
    }, [navigate]);

    const handleSuccessfulAuthentication = useCallback(
        (session: IAuthSession) => {
            setAuthSessionStoredValue(session);

            // Navigate to the target route that the user has tried to access
            // but got logged out (expired session)
            handleNavigate();
        },
        [setAuthSessionStoredValue, handleNavigate],
    );

    const error = useMemo<IAuthApiError>(
        () =>
            // TODO: should we have only one at a time or full array?: EONFEH-16031
            [errorOnLogin, errorOnMultifactor, errorOnLogout].find(
                (item) => item !== null,
            ) as IAuthApiError,
        [errorOnLogin, errorOnMultifactor, errorOnLogout],
    );

    // for multi-factor step
    const onTokenSubmit = useCallback(
        async (token: string) => {
            try {
                const signInMultiResponse =
                    await mutateSignInMultiFactor(token);
                const authSessionValue =
                    generateAuthSessionObject(signInMultiResponse);

                if (signInMultiResponse) {
                    handleSuccessfulAuthentication(authSessionValue);
                }
            } catch (error) {
                console.error('onTokenSubmit:error', error);
            }
        },
        [handleSuccessfulAuthentication, mutateSignInMultiFactor],
    );

    const isLoading = useMemo<boolean>(
        () =>
            [
                isLoadingOnLogin,
                isLoadingMultiFactor,
                isLoadingOnLogout,
                isLoadingAuthResourceLink,
            ].some((item) => item === true),
        [
            isLoadingOnLogin,
            isLoadingMultiFactor,
            isLoadingOnLogout,
            isLoadingAuthResourceLink,
        ],
    );

    useEffect(() => {
        if (searchParams.get(CODE) && searchParams.get(STATE)) {
            signIn();
        }
    }, [searchParams, signIn]);

    useEffect(() => {
        const session = getAuthSessionValue();
        const isLoggedIn = !!session && session.isAuthenticated;

        // If we have server response
        // Also, on logging in without 2FA activated this is called twice -
        // so, check if we have already logged the user in before logging in again
        if (!!signInResponse && !isLoggedIn) {
            const authSessionValue = generateAuthSessionObject(signInResponse);

            if (signInResponse?.multiFactorAuthentication) {
                // on multi-factor - store 'pre-auth' session
                setTempAuthStoredValue(authSessionValue);
            } else {
                // on simple sign in - store session, call success
                handleSuccessfulAuthentication(authSessionValue);
            }
        }

        if (
            !!signInResponse &&
            isLoggedIn &&
            location.pathname.includes(Routes.LOGIN)
        ) {
            handleNavigate();
        }
    }, [
        signInResponse,
        setTempAuthStoredValue,
        handleSuccessfulAuthentication,
        getAuthSessionValue,
        location.pathname,
        navigate,
        handleNavigate,
    ]);

    const value = useMemo<IAuthContext>(() => {
        const hasMultiFactorAuth = !!signInResponse?.multiFactorAuthentication;
        const hasCodeFromUrl = !!searchParams.get(CODE) || false;

        const hasCode =
            error?.message === ErrorMessageInvalidMfaToken || hasCodeFromUrl;

        const session = getAuthSessionValue();

        return {
            submit,
            onTokenSubmit,
            signOut,
            hasCode,
            hasMultiFactorAuth,
            isLoading,
            error: error ?? null,
            isLoggedIn: !!session && session.isAuthenticated,
        };
    }, [
        signInResponse?.multiFactorAuthentication,
        searchParams,
        error,
        getAuthSessionValue,
        submit,
        onTokenSubmit,
        signOut,
        isLoading,
    ]);
    return (
        <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
    );
};

const useAuthContext = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('`useAuth` must be used within `AuthProvider`');
    }

    return context;
};

export default useAuthContext;
