import { useMemo } from 'react';

import ReactCountryFlag from 'react-country-flag';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';

import ArrowForward from '@mui/icons-material/ArrowForward';
import FeedIcon from '@mui/icons-material/Feed';
import LogoutIcon from '@mui/icons-material/Logout';
import SearchIcon from '@mui/icons-material/Search';
import {
    AppBar,
    Avatar,
    Badge,
    Button,
    InputAdornment,
    MenuItem,
    Select,
} from '@mui/material';

import { Icon } from '@eon-home/react-library';

import { Routes } from '@api/tools';

import { CountryCode, LanguageCode } from '@components/header/enums';
import { languageToLocale, localeToLanguage } from '@tools/enums/locales';

import { ControlledTextField } from '@components/textfield';
import useAuthContext from '@context/auth-provider';
import useUserContext from '@context/user-provider';
import i18n from '@src/i18n';
import {
    replaceHyphensWithUnderscores,
    replaceUnderscoresWithHyphens,
} from '@tools/utils';

import DoubleFactorIcon from '@assets/icons/2fa.svg';
import EonLogo from '@assets/icons/eon-logo.svg';

import styles from '@components/header/index.module.scss';

export const Header = () => {
    const { t } = useTranslation();
    const { control, handleSubmit } = useForm();
    const { userData } = useUserContext();

    const { signOut } = useAuthContext();

    const navigate = useNavigate();

    const onSubmit = ({ searchInput }: { searchInput: string }) => {
        if (searchInput !== '') {
            navigate(`/dashboard/search/${encodeURIComponent(searchInput)}`);
        }
    };

    const languageMenuItems = useMemo(
        () => [
            {
                languageCode: LanguageCode.ENGLISH,
                countryCode: CountryCode.ENGLISH,
                language: t('English'),
            },
            {
                languageCode: LanguageCode.DEUTSCH,
                countryCode: CountryCode.DEUTSCH,
                language: t('Deutsch'),
            },
        ],
        [t],
    );

    return (
        <AppBar className={styles.header}>
            <div className={`header-left ${styles.header__left}`}>
                <Link to={Routes.DASHBOARD} className={styles.logo}>
                    <Icon src={EonLogo} />
                </Link>

                <Select
                    value={
                        localeToLanguage[
                            replaceHyphensWithUnderscores(i18n.language)
                        ]
                    }
                    onChange={(e) =>
                        i18n.changeLanguage(
                            replaceUnderscoresWithHyphens(
                                languageToLocale[e.target.value],
                            ),
                        )
                    }
                    className={styles.select}
                    MenuProps={{
                        className: styles.dropdown,
                    }}
                >
                    {languageMenuItems.map(
                        ({ languageCode, countryCode, language }) => (
                            <MenuItem key={languageCode} value={languageCode}>
                                <ReactCountryFlag countryCode={countryCode} />
                                {language}
                            </MenuItem>
                        ),
                    )}
                </Select>
            </div>

            <div className={styles.header__middle}>
                <Button
                    onClick={() =>
                        document.body.classList.toggle('is--expanded')
                    }
                    className={`menu-toggle ${styles.back}`}
                >
                    <ArrowForward />
                </Button>

                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className={styles.header__search}
                >
                    <ControlledTextField
                        name="searchInput"
                        variant="outlined"
                        control={control}
                        data-testid="header-search-input"
                        InputProps={{
                            className: styles.header__searchfield,
                            placeholder: t('common.headerSearch'),
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon
                                        className={styles.header__searchicon}
                                    />
                                </InputAdornment>
                            ),
                        }}
                    />
                </form>
            </div>

            <div className={styles.header__right}>
                <div className={styles.health}>
                    <Badge className={styles.badge}>
                        <FeedIcon />
                    </Badge>
                </div>

                {userData?.email && (
                    <Link
                        className={styles.account}
                        to={Routes.PROFILE_SUMMARY}
                        data-testid="profile-summary-link"
                    >
                        <span
                            className="account-email"
                            data-testid="account-email"
                        >
                            {userData.email}
                        </span>

                        <Avatar
                            className={styles.avatar}
                            data-testid="account-avatar"
                        >
                            {userData.multiFactorAuthentication ? (
                                <Icon src={DoubleFactorIcon} />
                            ) : (
                                JSON.stringify(userData.email)
                                    .slice(1, 3)
                                    .toUpperCase()
                            )}
                        </Avatar>
                    </Link>
                )}

                <Button
                    onClick={signOut}
                    className={styles.logout}
                    id="logout-button"
                >
                    <span className={styles.header__logout}>
                        {t('common.logout')}
                    </span>

                    <LogoutIcon />
                </Button>
            </div>
        </AppBar>
    );
};

export default Header;
