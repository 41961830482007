import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import MailIcon from '@mui/icons-material/MailOutline';

import { Button, UnknownFunction } from '@eon-home/react-library';

import styles from '@components/generic-error/index.module.scss';

export interface GenericErrorProps {
    header?: React.ReactNode;
    onRetry?: UnknownFunction;
    centerText?: boolean;
}

export const GenericError: FC<Readonly<GenericErrorProps>> = ({
    header,
    onRetry,
    centerText = false,
}: GenericErrorProps) => {
    const { t } = useTranslation();

    const email = 'fehit-support@eon.com';

    return (
        <>
            {header}

            <div
                className={`${styles.generic_error} ${
                    centerText ? styles.generic_error__center : ''
                }`}
            >
                <h1>{t('common.genericErrorTitle')}</h1>

                <p>{t('common.genericErrorContent')}</p>
                {email && (
                    <a
                        href={`mailto:${email}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <MailIcon />
                        {email}
                    </a>
                )}

                {typeof onRetry === 'function' && (
                    <Button
                        className="btn"
                        small
                        type="button"
                        onClick={onRetry}
                    >
                        {t('common.retry')}
                    </Button>
                )}
            </div>
        </>
    );
};

export default GenericError;
